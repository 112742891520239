
@font-face {
  font-family: 'Castledown';
  src: url('fonts/castledown_bold-webfont.ttf')  format('truetype'),
       url('fonts/Castledown_Bold.woff') format('woff'); /* Modern Browsers */
  font-weight: bold;
}

@font-face {
  font-family: 'Castledown';
  src: url('fonts/Castledown-Regular.ttf')  format('truetype'),
       url('fonts/Castledown-Regular.woff') format('woff'); /* Modern Browsers */
  font-weight: normal;
}


@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Bold.ttf')  format('truetype'),
       url('fonts/Gilroy-Bold.otf') format('otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Regular.ttf')  format('truetype'),
       url('fonts/Gilroy-Regular.otf') format('otf'); 
  font-weight: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-RegularItalic.ttf')  format('truetype'),
       url('fonts/Gilroy-RegularItalic.otf') format('otf'); 
  font-weight: normal;
  font-style: italic;
}


